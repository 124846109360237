/// <reference path="../../ext/event-emitter.ts"/>
// msgDiag.ts -- receives messaging diagnostics

interface LogRecord {
    path: string,
    msg: Record<string,any>,
    time: number,
}

interface CircularBuffer<TYP> {
    readonly IndexError : {};
    toString() : string;
    get(i:number) : TYP|undefined;
    set(i:number, v:TYP) : void;
    push(v:TYP) : void;
    minIdx() : number;
    getContents() : {minIdx:number,values:TYP[]};
}

declare var logNotification : (path:string, msg:Record<string,any>) => void;
declare var NOTIFICATION_LOG : CircularBuffer<LogRecord>;
declare var NOTIFICATION_MONITOR : EventEmitter<'added'>;

(function(global:typeof globalThis) {

    // Circular buffer storage. Externally-apparent 'length' increases indefinitely
    // while any items with indexes below length-n will be forgotten (undefined
    // will be returned if you try to get them, trying to set is an exception).
    // n represents the initial length of the array, not a maximum
    // taken from https://stackoverflow.com/questions/1583123/circular-buffer-in-javascript
    class CircularBuffer<TYP> {
        constructor(n:number) {
            this._array= new Array(n);
        }

        private length: number = 0;
        private _array: (TYP|undefined)[];
        readonly IndexError : {};

        protected static staticConstruct = (() => {
            const cbp = CircularBuffer.prototype;

            cbp.length = 0;
            (cbp as any).IndexError = {};
        })();

        public toString() : string {
            return '[object CircularBuffer('+this._array.length+') length '+this.length+']';
        }

        public get(i:number) : TYP|undefined {
            const arrLen = this._array.length;
            if (i<0 || i<this.length-arrLen) return undefined;
            return this._array[i%arrLen];
        }

        public set(i:number, v:TYP) : void {
            const arrLen = this._array.length;
            if (i<0 || i<this.length-arrLen) throw this.IndexError;
            while (i>this.length) {
                this._array[this.length%arrLen]= undefined;
                this.length++;
            }
            this._array[i%arrLen]= v;
            if (i==this.length) this.length++;
        }

        public push(v:TYP) : void {
            this._array[(this.length++)%this._array.length]= v;
        }

        public minIdx() : number {
            return Math.max(this.length-this._array.length,0);
        }

        public getContents() : {minIdx:number,values:TYP[]} {
            const arrLen = this._array.length;
            const minIdx = Math.max(this.length-arrLen,0);
            const offset = this.length % arrLen;
            const hasWrap = arrLen && offset <= (minIdx % arrLen);
            return {
                minIdx:minIdx,
                values:this._array.slice(minIdx % arrLen, hasWrap ? arrLen : offset)
                    .concat(hasWrap ? this._array.slice(0, offset) : []) as TYP[]
            };
        }
    }

    const NOTIFICATION_LOG = new CircularBuffer<LogRecord>(1000);
    const NOTIFICATION_MONITOR = new EventEmitter<'added'>();

    const logNotification = (path:string, msg:Record<string,any>) : void => {
        const log : LogRecord = {path:path,msg:msg,time:Date.now()};
        NOTIFICATION_LOG.push(log);
        NOTIFICATION_MONITOR.emit('added', log);
    }

    global.logNotification = logNotification;
    global.NOTIFICATION_LOG = NOTIFICATION_LOG;
    global.NOTIFICATION_MONITOR = NOTIFICATION_MONITOR;
})(this);
