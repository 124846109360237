/* sdxf2.ts

Encode and decode javascript objects into a binary format
*/

// -- START of interface declaration

interface SDXF2_ByteReader {
	len : number;										// length of source
	off : number;										// offset of current position into source
	readByte : () => number;								// function to read the next value from src
	readBytes : (len:number) => Uint8Array|number[];		// function to read multiple bytes from src
	readNumber():number;
	readInt(len:number):number;
}
interface SDXF2_ByteReaderConstructor {
    new(buf:string|ArrayBuffer|Uint8Array): SDXF2_ByteReader;
}
declare var SDXF2_ByteReader : SDXF2_ByteReaderConstructor;

interface SDXF2_ByteWriter {
	dest : number[];				// results of output
	writeNumber(value:number):void;
	writeByte(val:number) : void;
	writeBytes(val:number[]) : void;
	writeInt(val:number) : void;
	toArray():number[];
	toArrayBuffer():ArrayBuffer;
	toString():string;
}
interface SDXF2_ByteWriterConstructor {
    new(): SDXF2_ByteWriter;
}
declare var SDXF2_ByteWriter : SDXF2_ByteWriterConstructor;

interface SDXF2_Sdxf2Writer {
	dest : SDXF2_ByteReader;				// results of encoding
	createMarker(id:number) : void;
	createArrayMarker(id:number) : void;
    createInt(id:number, val:number) : void;
	createString(id:number, val:string) : void;
	createFloat(id:number, val:number) : void;
	enterChunk(id:number) : void;
	leaveChunk() : void;
	writeDictionary(node:any) : void;
	writeNode(node:any, map:Record<string,Record<string,number>>, typeName?:string) : void;
}
interface SDXF2_Sdxf2WriterConstructor {
    new(): SDXF2_Sdxf2Writer;
}
declare var SDXF2_Sdxf2Writer : SDXF2_Sdxf2WriterConstructor;

interface SDXF2_Sdxf2Reader {
	readNode(dict?:Record<number,string>, nodeName?:string):any;
}
interface SDXF2_Sdxf2ReaderConstructor {
    new(buf:string|ArrayBuffer|Uint8Array) : SDXF2_Sdxf2Reader;
}
declare var SDXF2_Sdxf2Reader : SDXF2_Sdxf2ReaderConstructor;

interface SDXF2 {
	ByteReader: typeof SDXF2_ByteReader,
	ByteWriter: typeof SDXF2_ByteWriter,
	Sdxf2Reader: typeof SDXF2_Sdxf2Reader,
	Sdxf2Writer: typeof SDXF2_Sdxf2Writer,
	sdxf2_encode: (node:any, dict?:Record<string,Record<string,number>>) => SDXF2_ByteWriter;
	sdxf2_decode: (buf:string|ArrayBuffer|Uint8Array, dict?:Record<number,string>) => any,
	sdxf2_dict_encode2decode: (dict:Record<string,Record<string,number>>) => Record<number,string>,
}
declare var SDXF2 : SDXF2;

// -- END of interface declaration

(function(global:typeof globalThis) {
	
class ByteReader {
	constructor(buf:string|ArrayBuffer|Uint8Array) {
		this.readByte = this.readByteRaw;
		this.readBytes = this.readBytesRaw;
		this.readBytesBuf = this.readBytesRaw;
		if(typeof buf == 'string') {
			this.src = buf;
			this.len = buf.length;
			this.readByte = this.readByteChar;
			this.readBytes = this.readBytesBufChar;
		} else if('ArrayBuffer' in window && buf instanceof ArrayBuffer) {
			this.src = new Uint8Array(buf);
			this.len = buf.byteLength;
		} else if(buf instanceof Uint8Array) {
			this.src = buf;
			this.len = buf.length;
		}
		this.off = 0;
	}

	private src : string|Uint8Array;							// value to read from
	public len : number;										// length of source
	public off : number;										// offset of current position into source
	public readByte : () => number;								// function to read the next value from src
	public readBytes : (len:number) => Uint8Array|number[];		// function to read multiple bytes from src
	public readBytesBuf : (len:number) => Uint8Array;			// function to read multiple bytes from src

	private readByteRaw():number {
		return (this.src as Uint8Array)[this.off++];
	}

	private readBytesRaw(len:number):Uint8Array {
		const result = (this.src as Uint8Array).slice(this.off, this.off + len);
		this.off += len;
		return result;
	}

	private readByteChar():number {
		return (this.src as string).charCodeAt(this.off++);
	}

	private readBytesChar(len:number):number[] {
		const result = [];
		for(let idx=0; idx < len; ++idx) {
			result.push((this.src as string).charCodeAt(this.off++));
		}
		return result;
	}

	private readBytesBufChar(len:number):Uint8Array {
		let tempArray = new ArrayBuffer(len);
		const tempView = new Uint8Array(tempArray);

		for(let idx=0; idx < len; ++idx) {
			tempView[idx] = (this.src as string).charCodeAt(this.off++);
		}
		return tempView;
	}

	public readNumber():number {
		const byte1 = this.readByte();
		if((byte1&0x80) == 0) return byte1;

		const byte2 = this.readByte();
		if((byte2&0xc0) != 0x80) { debugger; throw new Error('received bad continuation number byte ' + byte2); }
		if((byte1&0xe0) == 0xc0) {
			// 2 bytes
			return (byte1&0x1f) << 6 | (byte2&0x3f);
		}

		const byte3 = this.readByte();
		if((byte3&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte3);
		if((byte1&0xf0) == 0xe0) {
			// 3 bytes
			return ((byte1 & 0x0f) << 12) | ((byte2 & 0x3f) << 6) | (byte3 & 0x3f);
		}

		const byte4 = this.readByte();
		if((byte4&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte4);
		if((byte1&0xf8) == 0xf0) {
			// 4 bytes
			return ((byte1 & 0x07) << 18) | ((byte2 & 0x3f) << 12) | ((byte3 & 0x3f) << 6) | (byte4 & 0x3f);
		}

		const byte5 = this.readByte();
		if((byte5&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte5);
		if((byte1&0xfc) == 0xf8) {
			// 5 bytes
			return ((byte1 & 0x03) << 24) | ((byte2 & 0x3f) << 18) | ((byte3 & 0x3f) << 12) | ((byte4 & 0x3f) << 6) | (byte5 & 0x3f);
		}

		const byte6 = this.readByte();
		if((byte6&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte6);
		if((byte1&0xfe) == 0xfc) {
			// 6 bytes
			return ((byte1 & 0x01) << 30) | ((byte2 & 0x3f) << 24) | ((byte3 & 0x3f) << 18) | ((byte4 & 0x3f) << 12) |
				((byte5 & 0x3f) << 6) | (byte6 & 0x3f);
		}

		// numbers beyond this point are likely greater than 32 bits and should be returned as non-integers

		const byte7 = this.readByte();
		if((byte7&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte7);
		if(byte1 == 0xfe) {
			// 6 bytes (excluding first byte)
			return ((byte2 & 0x3f) * 1073741824.0) + ((byte3 & 0x3f) * 16777216.0) + ((byte4 & 0x3f) * 262144.0) +
				((byte5 & 0x3f) * 4096.0) + ((byte6 & 0x3f) * 64.0) + (byte7 & 0x3f);
		}

		if(byte1 == 0xff) {
			const byte8 = this.readByte();
			if((byte8&0xc0) != 0x80) throw new Error('received bad continuation number byte ' + byte8);

			// 7 bytes (excluding first byte)
			return ((byte2 & 0x3f) * 68719476736.0) + ((byte3 & 0x3f) * 1073741824.0) + ((byte4 & 0x3f) * 16777216.0) +
				((byte5 & 0x3f) * 262144.0) + ((byte6 & 0x3f) * 4096.0) + ((byte7 & 0x3f) * 64.0) + (byte8 & 0x3f);
		} else throw new Error('received bad lead number byte ' + byte1);
	}

	public readInt(len:number):number {
		let result = 0;
		const end = this.off + len;
		let thisByte = this.readByte();
		if ((thisByte & 0x80) != 0) {
			// negative
			for(;;) {
				result = (result * 256) - (thisByte ^ 0xFF);
				if(this.off == end) break;
				thisByte = this.readByte();
			}
			--result; // adjust for two's compliment
		} else {
			// positive
			for(;;) {
				result = (result * 256) + thisByte;
				if(this.off == end) break;
				thisByte = this.readByte();
			}
		}
		return result;
	}
}

class ByteWriter {
	constructor() {
		this.dest = [];
	}
	public dest : number[];				// results of output

	public writeNumber(value:number) : void {
		if(value <= 0x7f) {
			this.dest.push(value);
		} else if(value <= 0x07ff) {
			this.dest.push(0xc0 | (value >>> 6), 0x80 | (value & 0x3f));
		} else if(value <= 0xffff) {
			this.dest.push(0xe0 | (value >>> 12), 0x80 | ((value >>> 6) & 0x3f), 0x80 | (value & 0x3f));
		} else if(value <= 0x1FFFFF) {
			this.dest.push(0xf0 | (value >>> 18), 0x80 | ((value >>> 12) & 0x3f), 0x80 | ((value >>> 6) & 0x3f), 0x80 | (value & 0x3f));
		} else if(value <= 0x3FFFFFF) {
			this.dest.push(0xf8 | (value >>> 24), 0x80 | ((value >>> 18) & 0x3f), 0x80 | ((value >>> 12) & 0x3f),
				0x80 | ((value >>> 6) & 0x3f), 0x80 | (value & 0x3f));
		} else if(value <= 0x7FFFFFFF) {
			this.dest.push(0xfc | (value >>> 30), 0x80 | ((value >>> 24) & 0x3f), 0x80 | ((value >>> 18) & 0x3f),
				0x80 | ((value >>> 12) & 0x3f), 0x80 | ((value >>> 6) & 0x3f), 0x80 | (value & 0x3f));
		// numbers beyond this point are likely greater than 32 bits and should be assumed non-integer
		} else if(value <= 0xFFFFFFFF) {
			this.dest.push(0xfe, 0x80 | ((value / 1073741824.0) & 0x3f), 0x80 | ((value / 16777216.0) & 0x3f),
				0x80 | ((value / 262144.0) & 0x3f), 0x80 | ((value / 4096.0) & 0x3f), 0x80 | ((value / 64.0) & 0x3f),
				0x80 | (value & 0x3f));
		} else if(value <= 0x3FFFFFFFFF) {
			this.dest.push(0xff, 0x80 | ((value / 68719476736.0) & 0x3f), 0x80 | ((value / 1073741824.0) & 0x3f),
				0x80 | ((value / 16777216.0) & 0x3f), 0x80 | ((value / 262144.0) & 0x3f), 0x80 | ((value / 4096.0) & 0x3f),
				0x80 | ((value / 64.0) & 0x3f), 0x80 | (value & 0x3f));
		} else throw new Error('Attempt to write bad length value to SDXF2 structure');
	}

	public writeByte(val:number) : void {
		this.dest.push(val);
	}

	public writeBytes(val:number[]) : void {
		if(val.length) {
			// avoid overwhelming the call stack
			while(val.length > 10000) {
				Array.prototype.push.apply(this.dest, val.splice(0, 10000));
			}
			if(val.length) {
				Array.prototype.push.apply(this.dest, val);
			}
		}
	}

	public writeInt(val:number) : void {
		const result = [];
		if(val < 0) {
			// negative
			let thisByte = 0;
			val = -1 - val;
			while(val <= -1) {
				thisByte = (val & 0xFF) ^ 0xFF;
				result.push(thisByte);
				val = (val / 256);
			}
			if((thisByte & 0x80) == 0) result.push(0xFF);
		} else {
			// positive
			let thisByte = 0;
			while(val >= 1) {
				thisByte = (val & 0xFF);
				result.push(thisByte);
				val = (val / 256);
			}
			if((thisByte & 0x80) != 0) result.push(0x00);
		}
		this.writeNumber(result.length);
		this.writeBytes(result.reverse());
	}

	public toArray():number[] {
		return this.dest;
	}

	public toArrayBuffer():ArrayBuffer	{
		if(!('ArrayBuffer' in window)) throw new Error('ArrayBuffer not supported by this browser');
		const len = this.dest.length;
		const result = new ArrayBuffer(len);
		const view = new Uint8Array(result);
		for(let idx=0; idx < len; ++idx) view[idx] = this.dest[idx];
		return result;
	}

	public toString():string {
		const result : string[] = [];
		const len = this.dest.length;
		for(let idx=0; idx < len; ++idx) result.push(String.fromCharCode(this.dest[idx]));
		return result.join('');
	}
}

const enum TypeBits {
	Special = 0x00,
	Structured = 0x20,
	Binary = 0x40,
	Numeric = 0x60,
	Char = 0x80,
	Float = 0xA0,
	UTF8 = 0xC0,
	Reserved = 0xE0,

	ShortLen = 0x01,
	Array = 0x02,
	ShortChunk = 0x04,
}

type SpecialType = null|false|true|undefined;

const SPECIALS : SpecialType[] = [
	null,
	false,
	true,
	undefined,
];

class Sdxf2Writer {
	constructor() {
		this.dest = new ByteWriter();
		this.chunks = [];
	}
	public dest : ByteWriter;				// results of encoding
	private thisChunk : null|ByteWriter;	// current enclosing object
	private chunks : ByteWriter[];			// list of enclosing objects currently in the hierarchy

	public createMarker(id:number) {
		this.dest.writeNumber(id);
		this.dest.writeByte(TypeBits.Special);
		this.dest.writeNumber(0);
	}
	
	public createSpecial(id:number, val:SpecialType) {
		const len = SPECIALS.length;
		let idx = 0;
		for(; idx < len; idx++) {
			if(val === SPECIALS[idx]) break;
		}
		if(idx >= len) throw new Error('Attempt to write invalid special value');

		this.dest.writeNumber(id);
		this.dest.writeByte(TypeBits.Special | TypeBits.ShortChunk);
		this.dest.writeNumber(idx);
	}
	
	public createArrayMarker(id:number) {
		this.dest.writeNumber(id);
		this.dest.writeByte(TypeBits.Special | TypeBits.Array);
		this.dest.writeNumber(0);
		this.dest.writeNumber(0);
	}

	public createInt(id:number, val:number) {
		this.dest.writeNumber(id);
		if(val <= 127 && val >= -128) {
			this.dest.writeByte(TypeBits.Numeric | TypeBits.ShortChunk);
			this.dest.writeByte(val & 0xff);
		} else {
			this.dest.writeByte(TypeBits.Numeric);
			this.dest.writeInt(val);
		}
	}

	public createString(id:number, val:string) {
		const encoded = new ByteWriter();
		const len = val.length;
		for(let idx=0; idx < len; ++idx) {
			encoded.writeNumber(val.charCodeAt(idx));
		}
		const encStr = encoded.dest;

		this.dest.writeNumber(id);
		if(encStr.length == 1) {
			this.dest.writeByte(TypeBits.UTF8 | TypeBits.ShortChunk);
			this.dest.writeByte(encStr[0]);
		} else {
			this.dest.writeByte(TypeBits.UTF8);
			this.dest.writeNumber(encStr.length);
			this.dest.writeBytes(encStr);
		}
	}

	public createFloat(id:number, val:number) {
		if(!('DataView' in window && 'ArrayBuffer' in window)) throw new Error('float encode support not present in this browser');
		const tempArray = new ArrayBuffer(8);
		(new DataView(tempArray)).setFloat64(0, val, false);
		const result = [];
		const len = tempArray.byteLength;
		const view = new Uint8Array(tempArray);
		for(let idx = 0; idx < len; ++idx) result.push(view[idx]);

		this.dest.writeNumber(id);
		this.dest.writeByte(TypeBits.Float);
		this.dest.writeNumber(result.length);
		this.dest.writeBytes(result);
	}

	public enterChunk(id:number) {
		this.dest.writeNumber(id);
		this.dest.writeByte(TypeBits.Structured);
		if(this.thisChunk) this.chunks.push(this.thisChunk);
		this.thisChunk = this.dest;
		this.dest = new ByteWriter();
	}

	public leaveChunk() {
		if(!this.thisChunk) throw new Error('Attempt to leave nonexistent chunk');
		this.thisChunk.writeNumber(this.dest.dest.length);
		this.thisChunk.writeBytes(this.dest.dest);
		this.dest = this.thisChunk;
		this.thisChunk = null;
		if(this.chunks.length) this.thisChunk = this.chunks.pop() || null;
	}

	private collectProperties(attrDefs:Record<string,Record<string,number>>, nextId:{val:number}, node:any, typeName?:string, parentTypeName?:string) {
		
		const processEntry = (value:any, name:string|null, parentName:string) => {
			
			let attrDef : null|Record<string,number> = null;
			if(parentName in attrDefs) {
				attrDef = attrDefs[parentName];
			} else if(parentName) {
				attrDef = attrDefs[parentName] = {};
				attrDef[''] = nextId.val++;
			}
			
			if(!name) return;
			
			if(typeof value == 'object' && value !== null) {
				this.collectProperties(attrDefs, nextId, value, name, parentName);
			} else {
				if(!attrDef) { // in case we have attributes/arrays in the outer (unnamed) object?
					attrDef = attrDefs[parentTypeName||''] = {};
					attrDef[''] = nextId.val++;
				}
				if(!(name in attrDef)) {
					attrDef[name] = nextId.val++;
				}
			}
		}
		
		if(Array.isArray(node)) {
			const len = node.length;
			if(len < 2) {
				// force a child attribute for use with the empty-array marker we're likely to shove
				processEntry(null, typeName||'', parentTypeName||'');
			}
			for(let idx = 0; idx < len; ++idx) {
				processEntry(node[idx], typeName||'', parentTypeName||'');
			}
		} else {
			let foundProp = false;
			for(let name in node) {
				processEntry(node[name], name, typeName||'');
				foundProp = true;
			}
			if(!foundProp) {
				// empty object, we need to document it anyhow
				processEntry(null, null, typeName||'');
			}
		}
	}

	public writeDictionary(node:any) {
		const defMap : Record<string,Record<string,number>> = {};
		this.collectProperties(defMap, { val: 1 }, node);

		this.enterChunk(0);
		for(let key in defMap) {
			const def = defMap[key];
			const rootDef = def[''];
			this.createString(rootDef, key);
			let hasChildren = false;
			for(let attrKey in def) {
				if(!attrKey) continue;
				if(!hasChildren) this.enterChunk(rootDef);
				hasChildren = true;
				this.createString(def[attrKey], attrKey);
			}
			if(hasChildren) this.leaveChunk();
		}
		this.leaveChunk();
		return defMap;
	}

	private getShortcutAttr(obj:any):null|string {
		let result = null;
		let attr:null|string = null;
		for(attr in obj) {
			if(result !== null) return null;
			result = attr;
		}
		return attr;
	}

	public writeNode(node:any, map:Record<string,Record<string,number>>, typeName?:string, parentTypeName?:string) {
		if(!typeName) typeName = '';
		let def : Record<string,number>;

		const writeValue = (name:string, value:any) => {
			if(Array.isArray(value)) {
				const len = value.length;
				if(len < 2) writeArrayMarker(name);
				for(let idx = 0; idx < len; ++idx) {
					writeValue(name, value[idx]);
				}
			} else if(typeof value == 'object' && value !== null) {
				let shortcut = false;
				if(name != typeName) {
					const shortcutAttr = this.getShortcutAttr(value);
					if(shortcutAttr !== null) {
						const shortcutDef = map[name];
						const shortcutVal = value[shortcutAttr];
						if((typeof shortcutVal != 'object' || shortcutVal === null) && shortcutDef) {
							writeSimpleValue(shortcutAttr, shortcutVal, shortcutDef);
							shortcut = true;
						}
					}
				}
				if(!shortcut) this.writeNode(value, map, name, typeName);
			} else if(def) {
				writeSimpleValue(name, value, def);
			}
		};

		const writeArrayMarker = (name:string) => {
			if(!(name in def)) throw new Error('Attribute ' + (typeName ? typeName + '.' : '') + name + ' not found in map (writeArrayMarker)');
			const attrId = def[name];
			this.createArrayMarker(attrId);
		};

		const writeSimpleValue = (name:string, value:any, localDef:Record<string,number>) => {
			if(!(name in localDef)) throw new Error('Attribute ' + (typeName ? typeName + '.' : '') + name + ' not found in map');
			const attrId = localDef[name];
			switch(typeof value) {
				case 'number':
					if(value % 1 != 0 || value > 9007199254740991 || value < -9007199254740991) {
						this.createFloat(attrId, value);
					} else {
						this.createInt(attrId, value);
					}
					break;
				case 'undefined':
					this.createSpecial(attrId, value);
					break;
				case 'boolean':
					this.createSpecial(attrId, value);
					break;
				case 'string':
					this.createString(attrId, value);
					break;
				case 'object':
					if(value === null) {
						this.createSpecial(attrId, value);
					}
					break;
			}
		}

		if(Array.isArray(node)) {
			def = map[parentTypeName || ''];
			const len = node.length;
			if(len < 2) writeArrayMarker(typeName);
			for(let idx = 0; idx < len; ++idx) {
				this.writeNode(node[idx], map, typeName, parentTypeName);
			}
		} else {
			def = map[typeName];
			const rootDef = def && def[''];
			if(rootDef) this.enterChunk(rootDef);
			for(let name in node) {
				writeValue(name, node[name]);
			}
			if(rootDef) this.leaveChunk();
		}
	}
}

const enum TypeEnum {
	Special = 0,
	Structured = 1,
	Binary = 2,
	Numeric = 3,
	Char = 4,
	Float = 5,
	UTF8 = 6,
	Reserved = 7,
}

interface Chunk {
	ID : number,
	type: TypeEnum,
	len: number,
	off: number,
	value?: any,
}

class Sdxf2Reader {
	constructor(buf:string|ArrayBuffer|Uint8Array) {
		this.src = new ByteReader(buf);
		this.parentList = [];
	}
	private src : ByteReader;				// results of encoding
	private current : null|Chunk;			// last read object in this stream, if it is structured
	private parent : null|Chunk;			// current enclosing structured object
	private parentList : Chunk[];			// array of ancestor parents we are currently inside

	private next() : null|Chunk {
		if(this.current) {
			this.seekToEnd(this.current);
			this.current = null;
		}
		if((this.parent && this.parent.off + this.parent.len <= this.src.off) || this.src.off >= this.src.len) return null;

		// attempt to read the next ID, if we haven't ran off the end of the stream
		const newChunk : any = {
			ID : this.src.readNumber(),
		};

		// read the rest of the chunk
		const typeFlags = this.src.readByte();
		newChunk.type = ((typeFlags >> 5) & 0x07) as TypeEnum;
		if(typeFlags&TypeBits.ShortChunk) {
			newChunk.len = 1;
		} else {
			newChunk.len = this.src.readNumber();
		}

		if(typeFlags & ~(0xe0|TypeBits.ShortChunk|TypeBits.Array)) {
			throw new Error('use of compressed or encrypted chunks not implemented');
		}
		if(typeFlags & TypeBits.Array) {
			if (typeFlags & TypeBits.ShortChunk) throw new Error('chunk cannot be a short array');
			const arrayLen = this.src.readNumber();
			let elemLen = newChunk.len;

			newChunk.value = [];
			switch(newChunk.type as TypeEnum) {
				case TypeEnum.Special: // this is an untyped zero-length array
					break;
				case TypeEnum.Binary:
					for(let idx=0; idx < arrayLen; ++idx) {
						newChunk.value.push(this.src.readBytes(elemLen));
					}
					break;
				case TypeEnum.Numeric:
					for(let idx = 0; idx < arrayLen; ++idx) {
						newChunk.value.push(this.src.readInt(elemLen));
					}
					break;
				case TypeEnum.Float:
					if(!('DataView' in window && 'ArrayBuffer' in window)) throw new Error('float decode support not present in this browser');
					const tempArray = this.src.readBytesBuf(arrayLen*elemLen).buffer;
					const dataView = new DataView(tempArray);
					switch (elemLen) {
						case 8:
							for(let idx=0; idx < arrayLen; ++idx) {
								newChunk.value.push(dataView.getFloat64(elemLen*idx, true));
							}
							break;
						case 4:
							for(let idx=0; idx < arrayLen; ++idx) {
								newChunk.value.push(dataView.getFloat32(elemLen*idx, true));
							}
							break;
						default:
							throw new Error('unexpected numeric length');
					}
					break;
				case TypeEnum.Char:
					for(let idx=0; idx < arrayLen; ++idx) {
						let outVal = [];
						for(let ch=0; ch < elemLen; ++ch) {
							outVal.push(String.fromCharCode(this.src.readByte()));
						}
						newChunk.value.push(outVal.join(''));
					}
					break;
				case TypeEnum.UTF8:
					for(let idx=0; idx < arrayLen; ++idx) {
						let outVal = [];
						let endOff = this.src.off + elemLen;
						while(this.src.off < endOff) {
							outVal.push(String.fromCharCode(this.src.readNumber()));
						}
						newChunk.value.push(outVal.join(''));
					}
					break;
				default:
					throw new Error('unexpected type');
			}
		} else if(newChunk.type == TypeEnum.Structured) {
			this.current = newChunk;
			this.current!.off = this.src.off;
		} else {
			let elemLen = newChunk.len;
			switch (newChunk.type as TypeEnum) {
				case TypeEnum.Special:
					if(!elemLen) {
						newChunk.value = null;
					} else {
						newChunk.value = SPECIALS[this.src.readNumber()];
					}
					break;
				case TypeEnum.Binary:
					newChunk.value = this.src.readBytes(elemLen);
					break;
				case TypeEnum.Numeric:
					newChunk.value = this.src.readInt(elemLen);
					break;
				case TypeEnum.Float:
					if(!('DataView' in window && 'ArrayBuffer' in window)) throw new Error('float decode support not present in this browser');
					const tempArray = this.src.readBytesBuf(elemLen).buffer;
					const dataView = new DataView(tempArray);
					switch(elemLen) {
						case 8:
							newChunk.value = dataView.getFloat64(0, false);
							break;
						case 4:
							newChunk.value = dataView.getFloat32(0, false);
							break;
						default:
							throw new Error('unexpected numeric length');
					}
					break;
				case TypeEnum.Char: {
					let outVal = [];
					for(let idx=0; idx < elemLen; ++idx) {
						outVal.push(String.fromCharCode(this.src.readByte()));
					}
					newChunk.value = outVal.join('');
					break;
				}
				case TypeEnum.UTF8: {
					let outVal = [];
					let endOff = this.src.off + elemLen;
					while(this.src.off < endOff) {
						outVal.push(String.fromCharCode(this.src.readNumber()));
					}
					newChunk.value = outVal.join('');
					break;
				}
				default:
					throw new Error('unexpected type');
			}
			newChunk.off = this.src.off;
		}
		return newChunk;
	}

	private enterChunk() {
		if(!this.current) throw new Error('The current chunk cannot be entered');
		if(this.parent) this.parentList.push(this.parent);
		this.parent = this.current;
		this.current = null;
	}

	private leaveChunk() {
		if(!this.parent) throw new Error('There is no current chunk to leave');
		this.seekToEnd(this.parent);
		this.parent = null;
		this.current = null;
		if(this.parentList.length) this.parent = this.parentList.pop() || null;
	}

	private seekToEnd(target:Chunk) {
		const newOff = target.off + target.len;
		if (this.parent && this.parent.off + this.parent.len < newOff) throw new Error('inconsistent chunk size');
		if(newOff < this.src.off) throw new Error('attempt to seek backwards');
		this.src.off = newOff;
	}

	private readDictionary():Record<number,string> {
		if(!this.current || this.current.type != TypeEnum.Structured || this.current.ID != 0) {
			throw new Error('This is not a dictionary chunk');
		}
		const dict : Record<number,string> = {};
		const nodeTypes : Record<number,string> = {};
		this.enterChunk();
		let outerVal : null|Chunk;
		while(!!(outerVal = this.next())) {
			if(outerVal.type == TypeEnum.Structured) {
				if(!(outerVal.ID in nodeTypes)) throw new Error('encounter of node with unknown name');
				let nodeName = nodeTypes[outerVal.ID];
				this.enterChunk();
				while(!!(outerVal = this.next())) {
					if (typeof outerVal.value != 'string') throw new Error('unexpected chunk type in dictionary');
					const attrName = outerVal.value;
					dict[outerVal.ID] = nodeName + '/' + attrName;
				}
				this.leaveChunk();
			} else {
				if (typeof outerVal.value != 'string') throw new Error('unexpected chunk type in dictionary');
				let nodeName = outerVal.value;
				nodeTypes[outerVal.ID] = dict[outerVal.ID] = nodeName;
			}
		}
		this.leaveChunk();
		return dict;
	}

	public readNode(dict?:Record<number,string>, nodeName?:string):any {
		if(!nodeName) nodeName = '';
		let chunk : null|Chunk;
		let result : any = null;

		const pushValue = (parent:Record<string,any>,key:string,value:any) : void => {
			if(key in parent) {
				if(!Array.isArray(parent[key])) parent[key] = [parent[key]];
				parent[key].push(value);
			} else {
				parent[key] = value;
			}
		};

		while(!!(chunk = this.next())) {
			if(chunk.type == TypeEnum.Structured && chunk.ID == 0) {
				dict = this.readDictionary();
			} else {
				if(!dict || !(chunk.ID in dict)) throw new Error('received unknown chunk with ID ' + chunk.ID);
				const key = dict[chunk.ID];
				let childResult : any;
				if(chunk.type == TypeEnum.Structured) {
					this.enterChunk();
					if(!key && (!result || Array.isArray(result))) {
						if(!result) result = [];
						result.push(this.readNode(dict, key)) || {};
					} else {
						if(!result) result = {};
						childResult = this.readNode(dict, key) || {};
						pushValue(result, key, childResult);
					}
					this.leaveChunk();
				} else {
					let keys = key.split('/', 2);
					if(keys.length == 1) {
						if(!keys[0] && !result) {
							result = chunk.value;
						} else {
							if(!result) result = {};
							pushValue(result, keys[0], chunk.value);
						}
					} else if(keys[0] != nodeName) {
						childResult = {};
						childResult[keys[1]] = chunk.value;
						if(!result) result = {};
						pushValue(result, keys[0], childResult);
					} else {
						if(!result) result = {};
						pushValue(result, keys[1], chunk.value);
					}
				}
			}
		}
		return result;
	}
}

function sdxf2_encode(node:any, dict?:Record<string,Record<string,number>>):ByteWriter {
	const writer = new Sdxf2Writer();
	if(!dict) dict = writer.writeDictionary(node);
	writer.writeNode(node, dict);
	return writer.dest;
}

function sdxf2_decode(buf:string|ArrayBuffer|Uint8Array, dict?:Record<number,string>) : any {
	const reader = new Sdxf2Reader(buf);
	return reader.readNode(dict);
}

function sdxf2_dict_encode2decode(dict:Record<string,Record<string,number>>) : Record<number,string> {
	const result : Record<number,string> = {};
	const handleAttr = (subDict:Record<string,number>, prefix:string) => {
		for(let attr in subDict) {
			const id = subDict[attr];
			result[id] = attr ? prefix + '/' + attr : prefix;
		}
	}
	for(let prefix in dict) {
		handleAttr(dict[prefix], prefix);
	}
	return result;
}

global.SDXF2 = {
	ByteReader: ByteReader,
	ByteWriter: ByteWriter,
	sdxf2_encode: sdxf2_encode,
	sdxf2_decode: sdxf2_decode,
	sdxf2_dict_encode2decode: sdxf2_dict_encode2decode,
} as any;
})(this);
