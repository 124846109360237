/// <reference lib="es2015.promise"/>
/// <reference path="comet.ts"/>
/// <reference path="msgDiag.ts"/>
/// <reference path="net.ts"/>
/// <reference path="../../ext/event-emitter.ts"/>

interface PromiseConstructor {
	new(executor: (resolve: (value?: void|PromiseLike<void>) => void, reject: (reason?:any) => void) => void) : Promise<void>
}

const enum Time {
	Hours = 3600000,
	Minutes = 60000,
	Seconds = 1000,
}

declare var comet:COMETHandler;
declare var Promise:PromiseConstructor;
declare var requirejs:any;
declare var define:any;
declare var Vue:any;

/* portal.ts - Initial bootstrap of the main interface */
(function(global:typeof globalThis) {
	
	const SENTRY_DSN = 'https://1b6a846e8d2c4fd5b6c3a530b47cb31e@sentry.teamworkgroup.com/9';
	const SENTRY_ENVIRONMENT = 'next';
	const SENTRY_PERF_SAMPLING = 1.0;
	
	const doGenericCatch = (err:any, transaction:string, context?:any) => {
		if(!('Sentry' in global)) return;
		if(err instanceof Error) {
			Sentry.withScope((scope:any) => {
				if(context) scope.setExtra('context', context);
				scope.setTransaction(transaction);
				Sentry.captureMessage(err, Sentry.Severity.Error);
			});
		} else {
			const message = (typeof err == 'string' ? err : JSON.stringify(err));
			const stackMark = new Error(message);
			Sentry.withScope((scope:any) => {
				if(context) scope.setExtra('context', context);
				scope.setExtra('errorThrown', err);
				scope.setTransaction(transaction);
				Sentry.captureMessage(stackMark, Sentry.Severity.Error);
			});
		}
	}

	const loadScript = (url:string) : Promise<void> => {
		return new Promise((resolve:()=>void, reject:(e:any)=>void) => {
			let script = document.createElement('SCRIPT') as HTMLScriptElement;
			script.setAttribute('type', 'text/javascript');
			script.setAttribute('src', url);
			
			const resetCallbacks = () => {
				script.onload = oldOnload;
				script.onerror = oldOnerror;
				(script as any).onreadystatechange = oldOnreadystatechange;
			}
			
			// way too many different ways that browsers do callbacks...
			const oldOnload = script.onload;
			const oldOnerror = script.onerror;
			const oldOnreadystatechange = (script as any).onreadystatechange;
			
			script.onload = () => {
				resolve();
				resetCallbacks();
			};
			script.onerror = (evt) => {
				reject(evt);
				resetCallbacks();
			};
			(script as any).onreadystatechange = () => {
				if((script as any).readyState == 'loaded' || (script as any).readyState == 'complete') {
					resolve();
					resetCallbacks();
				}
			};
			try {
				document.body.appendChild(script);
			} catch(e) {
				reject(e);
				resetCallbacks();
			}
		});
	}

	let HEAD_OBJECT : HTMLHeadElement|null = null;
	const getHead = () : HTMLHeadElement|null => {
		if(!HEAD_OBJECT) {
			const headObjs = document.getElementsByTagName('HEAD');
			HEAD_OBJECT = (headObjs?.length && headObjs[0]) as (HTMLHeadElement|null);
			if(!HEAD_OBJECT){debugger; return null;}
		}
		return HEAD_OBJECT;
	}

	let STYLES_LOADED : {[key:string]:boolean} = {};
	const loadStyle = (url:string) => {
		if(STYLES_LOADED[url]) return;
		const headObj = getHead();
		if(!headObj){debugger; return;}
		
		let newUrl = url;
		//if(newUrl.indexOf('?') == -1) newUrl += '?' + Date.now();
		const style = document.createElement('LINK') as HTMLLinkElement;
		style.setAttribute('rel', 'stylesheet');
		style.setAttribute('type', 'text/css');
		style.setAttribute('href', newUrl);
		headObj.appendChild(style);
		STYLES_LOADED[url] = true;
	}

	const startupRequireJs = () => {
		define('@globals', [], {}); // dummy module for storing shared values
		define('vue', [], () => ({default:(global as any).Vue})); // ignore, Vue is a global

		// define dummy type definitions that are apparently being requested by the main script
		define('js/types/net', [], () => ({NetworkRequest:NetworkRequest,NetworkRequestError:NetworkRequestError}));
		define('js/types/comet', [], () => ({COMETHandler:COMETHandler}));
		define('js/types/event-emitter', [], () => ({EventEmitter:EventEmitter,WeakEventEmitter:WeakEventEmitter}));
		define('js/types/sdxf2', [], () => SDXF2);
		define('js/types/lzma2-js', [], () => ({LZMA:(global as any).LZMA}));
		define('js/types/msgDiag', [], () => {
			return {
				logNotification:window.logNotification,
				NOTIFICATION_LOG:window.NOTIFICATION_LOG,
				NOTIFICATION_MONITOR:window.NOTIFICATION_MONITOR,
			};
		});

		requirejs.config({});
	}

	// adapted from Sentry's error logging
	const logVueWarning = (msg: string, vm: any, trace: string): void => {

		//const COMPONENT_NAME_REGEXP = /(?:^|[-_/])(\w)/g;
		const ROOT_COMPONENT_NAME = 'root';
		const ANONYMOUS_COMPONENT_NAME = 'anonymous component';

		function getComponentName(vm: any): string {
			// Such level of granularity is most likely not necessary, but better safe than sorry. — Kamil
			if (!vm) {
				return ANONYMOUS_COMPONENT_NAME;
			}

			if (vm.$root === vm) {
				return ROOT_COMPONENT_NAME;
			}

			if (!vm.$options) {
				return ANONYMOUS_COMPONENT_NAME;
			}

			if (vm.$options.name) {
				return vm.$options.name;
			}

			if ((vm.$options as any)._componentTag) {
				return (vm.$options as any)._componentTag;
			}
/*
			// injected by vue-loader
			if ((vm.$options as any).__file) {
				const unifiedFile = (vm.$options as any).__file.replace(/^[a-zA-Z]:/, '').replace(/\\/g, '/');
				const filename = basename(unifiedFile, '.vue');
				return (
					this._componentsCache[filename] ||
						(this._componentsCache[filename] = filename.replace(COMPONENT_NAME_REGEXP, (_, c: string) =>
							c ? c.toUpperCase() : '',
						))
					);
			}
*/
			return ANONYMOUS_COMPONENT_NAME;
		}
		
		const metadata: any = {};

		if (vm) {
			try {
				metadata.componentName = getComponentName(vm);
				metadata.propsData = (vm as any).$options.propsData;
			} catch (_oO) {
				console.log('Unable to extract metadata from Vue component.');
			}
		}

		const err = new Error(msg);

		if('Sentry' in global) {
			Sentry.withScope((scope:any) => {
				scope.setContext('vue', metadata);
				Sentry.captureMessage(err, Sentry.Severity.Warning);
			});
		}
/*
		if (typeof currentWarnHandler === 'function') {
			currentWarnHandler.call(null, msg, vm, trace);
		}
*/
		// eslint-disable-next-line no-console
		console.error("[Vue warn]: " + msg + trace);
	}

	class CometHello extends EventEmitter<'error'|'success'|'timeout'> {
		constructor(comet:COMETHandler, sitePrefix:string) {
			super();

			this.promise = new Promise<any>((resolve, reject) => {
				const removeDisconnectListener = comet.once('disconnected', (info:any) => {
					removeConnectListener();
					reject(info);
				});

				const onCometConnected = (info:any) => {
					const action : NetworkRequestParameters = {
						path: '/siteHello',
						timeoutRetry: 15 * Time.Seconds,
					}
					action.parms = {site:sitePrefix};
					this.req = new NetworkRequest(action);
					this.req.once('success', (msg:any) => {
						removeDisconnectListener();
						resolve(msg?.result)
					});
					this.req.once('error', (msg:NetworkRequest) => {
						removeDisconnectListener();
						reject(msg)
					});
					this.req.on('timeout', () => {
						this.timeoutCount++;
						this.emit('timeout');
					});
					this.req.send();
				}
			
				const removeConnectListener = comet.once('connected', onCometConnected);
				comet.connect();
			})
			.then(
				(resolved:any) => {
					this.resolved = resolved || true;
					this.emit('success', resolved);
					return resolved;
				},
				(rejected:any) => {
					if(!rejected.status) rejected.status = COMETStatus[comet.status];
					this.rejected = rejected || true;
					this.emit('error', rejected);
					throw rejected;
				},
			);
		}

		public promise : Promise<any>;
		public resolved : any;
		public rejected : any;
		public timeoutCount : number = 0;
		private req : NetworkRequest;
	}

	function beginLoad() {
		if('performance' in global) global.performance.mark('begin-load');
        const messageText = document.getElementById('initialProgressMsg');
		if(messageText) messageText.textContent = 'Please wait...';

		const loadTimeout = () => {
			if(messageText) {
				messageText.textContent = 'Having trouble loading the page, please refresh';
			}
		}
		
		const comet = global.comet = new COMETHandler({
			url:'wss://msg-usw2.node.teamworkgroup.com:443/ws',
			uid:'twclient',
			pwd:'3Uw9CX1AWz',
		});

		let sitePrefix = '';
		let prefix = global.location.hostname.match(/^(.+)\.(teamworkgroup\.[^.]+|ttwg.biz)$/);
		if(prefix) {
			sitePrefix = prefix[1];
		}

		const loadTimer = window.setTimeout(loadTimeout, 30000);
		const cometHello = new CometHello(comet, sitePrefix);
		loadStyle('lib/main.css');
		loadScript('lib/main.js')
			.then(() => {
				// make the necessary overrides to register Vue files as they get loaded
				if('performance' in global) global.performance.mark('loaded-main');
				const oldDefine = define;
				let pendingComponents : string[]|null = null;

				define = function(this:any, name:string) {
					const ret = oldDefine.apply(this, arguments);
					if(name.match(/^vue\//)) {
						if(pendingComponents) {
							pendingComponents.push(name);
						} else {
							pendingComponents = [name];
							global.setTimeout(declareVueComponents, 0);
						}
					}
					return ret;
				}

				const declareVueComponents = () => {
					if(!pendingComponents) return;
					let thisPass = pendingComponents;
					pendingComponents = null;

					requirejs(thisPass, function() {
						let len = thisPass.length;
						for(let idx=0; idx < len; idx++) {
							let componentMatch = thisPass[idx].match(/^vue\/(.+)$/);
							if(componentMatch) {
								Vue.component(componentMatch[1], arguments[idx].default);
							}
						}
					});
				}

				return loadScript('lib/vue.js')
					.then(() => declareVueComponents());
			})
			.then(() => {
				// initialize RequireJS and other global libraries
				if('performance' in global) global.performance.mark('loaded-vue');
				startupRequireJs();
				requirejs(
					['@globals','js/main/init', 'keyboardevent-key-polyfill'],
					($Globals:any, init:any, keyEventPolyfill:any) =>
				{
					if('performance' in global) global.performance.mark('globals-init');
					keyEventPolyfill.polyfill();
					
					$Globals.comet = comet;
					$Globals.sitePrefix = sitePrefix;
					$Globals.reportLoadPromise = loadScript('lib/reports.js');

					if('Sentry' in global) {
						// Integrate Sentry with Vue now that we have both loaded and running
						Sentry.init({
							dsn: SENTRY_DSN,
							environment: SENTRY_ENVIRONMENT,
							tracesSampleRate: SENTRY_PERF_SAMPLING,
							integrations: [new Sentry.Integrations.Vue({
									Vue,
									attachProps: true,
									logErrors: true,
								}),
								new Sentry.Integrations.BrowserTracing()
							]
						});
						Vue.config.warnHandler = logVueWarning;
					}
					
					// we're initialized, transfer control to the main script area
					// (passing cometHello as we're not setup for intelligent error handling before the transfer occurs)
					window.clearTimeout(loadTimer);
					if(messageText) {
						messageText.textContent='';
					}
					init.init(cometHello);
				});
			})
			.catch((reason:any) => {
				doGenericCatch(reason, 'shell.shell.beginLoad.waitForMain');
			});
	}

	if('Sentry' in global) {
		Sentry.init({
			dsn: SENTRY_DSN,
			environment: SENTRY_ENVIRONMENT,
			tracesSampleRate: SENTRY_PERF_SAMPLING,
		});
	}

	global.onload = beginLoad;
})(this);
