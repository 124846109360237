interface PahoMessage {
    new(newPayload:string|ArrayBuffer):PahoMessage,

    readonly payloadString: string|null,
    readonly payloadBytes: ArrayBuffer|null,
    destinationName: string,
    qos:number,
    retained:boolean,
    topic:string,
    duplicate:boolean
}

enum PahoError { 
    OK = 0,
    CONNECT_TIMEOUT = 1,
    SUBSCRIBE_TIMEOUT = 2,
    UNSUBSCRIBE_TIMEOUT = 3,
    PING_TIMEOUT = 4,
    INTERNAL_ERROR = 5,
    CONNACK_RETURNCODE = 6,
    SOCKET_ERROR = 7,
    SOCKET_CLOSE = 8,
    MALFORMED_UTF = 9,
    UNSUPPORTED = 10,
    INVALID_STATE = 11,
    INVALID_TYPE = 12,
    INVALID_ARGUMENT = 13,
    UNSUPPORTED_OPERATION = 14,
    INVALID_STORED_DATA = 15,
    INVALID_MQTT_MESSAGE_TYPE = 16,
    MALFORMED_UNICODE = 17,
    BUFFER_FULL = 18,
}

interface PahoClient {
    new (uri:string,clientId:string): PahoClient,

    readonly host: string,
    readonly port: number,
    readonly path: string,
    readonly uri: string,
    readonly clientId: string,
    onConnected: (reconn:boolean, uri:string)=>void,
    disconnectedPublishing:boolean,
    disconnectedBufferSize:number,
    onConnectionLost: (info:{errorCode:PahoError,errorMessage:string,reconnect:boolean,uri:string,event:CloseEvent|undefined})=>void,
    onMessageArrived: (frame:PahoMessage)=>void,
    onMessageDelivered: (frame:PahoMessage)=>void,
    trace: (info:{severity:string,message:any})=>void,

    connect: (connectOptions:{
            timeout?: number,
            userName?: string,
            password?: string,
            willMessage?: PahoMessage,
            keepAliveInternal?: number,
            cleanSession?: boolean,
            useSSL?: boolean,
            invocationContext?: Object,
            onSuccess?: (info:{invocationContext:Object})=>void,
            onFailure?: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string})=>void,
            hosts?: string[],
            ports?: number[],
            reconnect?: boolean,
            mqttVersion?: number,
            mqttVersionExplicit?: boolean,
            uris?: string[]
        })=>void,
    subscribe: (filter:string, subscribeOptions:{
            qos?: number,
            invocationContext?: Object,
            onSuccess?: (info:{invocationContext:Object,grantedQos:number})=>void,
            onFailure?: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string})=>void,
            timeout?: number
        })=>void,
    unsubscribe: (filter:string, unsubscribeOptions:{
            invocationContext?: Object,
            onSuccess?: (info:{invocationContext:Object})=>void,
            onFailure?: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string})=>void,
            timeout?: number
        })=>void,
    send: (topic:string|PahoMessage,payload:string|ArrayBuffer,qos?:number,retained?:boolean)=>void,
    publish: (topic:string|PahoMessage,payload:string|ArrayBuffer,qos?:number,retained?:boolean)=>void,
    disconnect: ()=>void,
    getTraceLog: ()=>string[],
    startTrace: ()=>void,
    stopTrace: ()=>void,
    isConnected: ()=>boolean,
}    
