/// <reference path="../../ext/event-emitter.ts"/>
/// <reference path="comet.ts"/>

declare var comet:COMETHandler;

interface NetworkRequestParameters {
    path: string,
    timeoutRetry?: number,
    parms?: any,
}

interface NetworkRequestError extends Error {
    type: 'invalidPath'|'actionError',
    result?: any,
    original: any,
    cause?: Error,
}

class NetworkRequestError extends Error implements NetworkRequestError {
    constructor(msg:any,original:any,cause?:Error) {
        super(msg.action == 'invalidPath' ? 'unknown action "' + original.path + '" requested' :
            !('result' in msg) ? JSON.stringify(msg) : // escape clause in case we get something *really* weird out of this
            'message' in msg.result ? msg.result.message.toString() : msg.result.toString());
        this.name = 'NetworkRequestError';
        (Object as any).setPrototypeOf(this, new.target.prototype); // "as any" is because IE supports this but IE is only ES5

        this.type = msg.action;
        if(cause) this.cause = cause;
        if('result' in msg) this.result = msg.result;
        this.original = original;
    }
}

class StackTrace extends Error implements Error {
    constructor(msg:string) {
        super(msg);
        this.name = 'StackTrace';
        (Object as any).setPrototypeOf(this, new.target.prototype); // "as any" is because IE supports this but IE is only ES5
    }
}

interface NetworkResponseFrame {
    original: any,
    inner: any,
    result: string,
    perf?: any,
}

class NetworkRequest extends EventEmitter<'done'|'error'|'success'|'progress'|'timeout'> {
    constructor(parms?:NetworkRequestParameters) {
        super();
        const sid : string = parms?.parms?.sid || '';
        this.tag = sid + ':' + parseInt((Math.random() * 65535).toFixed(0), 10).toString();
        this.parms = parms || {path:''};
        this.channel = comet;
    }

    public parms : NetworkRequestParameters;
    readonly tag : string;
    public channel: COMETHandler;
    private removeCometMsgListener : null | (() => void) = null;
    private removeCometSessListener : null | (() => void) = null;
    private timeoutKey : number = 0;
    private requestPath : Error;
    private status : 'idle'|'pending'|'active' = 'idle';
    private sentryTrans : any;
    private sentrySpan : any;

    public send() : void {
        switch(this.channel.status) {
            case COMETStatus.disconnected:
            case COMETStatus.terminated:
                throw new Error("attempt to send a message over a closed connection");
            case COMETStatus.connecting:
            case COMETStatus.disconnecting:
            case COMETStatus.failure:
                this.channel.once("connected", () => { this.send(); });
                this.status = 'pending';
                return;
        }

        this.requestPath = new StackTrace('source of request');

        let request : any = {
            path: this.parms.path,
            sender: this.channel.rabbitQueueName(),
            tag: this.tag
        }

        if(!this.sentryTrans && 'Sentry' in window) this.sentryTrans = Sentry.startTransaction({
            name: 'mqtt:'+this.parms.path,
        });

        if(this.parms.parms) {
            request.parms = this.parms.parms;
        }
        if(!this.removeCometMsgListener) {
            this.removeCometMsgListener = this.channel.on('message', this.onMessage.bind(this));
        }
        if(!this.removeCometSessListener) {
            this.removeCometSessListener = this.channel.on('sessionChange', this.onSessionChanged.bind(this));
        }
        if(this.timeoutKey) {
            window.clearTimeout(this.timeoutKey);
            this.timeoutKey = 0;
        }
        if(this.parms.timeoutRetry) {
            this.timeoutKey = window.setTimeout(this.onTimeout.bind(this), this.parms.timeoutRetry);
        }
        this.status = 'active';
        window.setTimeout(() => {
            try {
                if(this.sentryTrans) {
                    this.sentrySpan = this.sentryTrans.startChild({
                        op: 'mqtt',
                        description: 'Browser network request',
                    });
                    this.sentrySpan.setData('req', request.parms);
                }
                this.channel.send('tw/config',request);
            } catch(e) {
                this.stopListeners();

                switch(this.channel.status) {
                    case COMETStatus.terminated:
                    case COMETStatus.connected:
                        // server isn't disconnected or isn't coming back?
                        if(this.sentryTrans) this.sentryTrans.setData('err', e);
                        this.emit('error', e);
                        this.requestDone('error', e);
                        return;
                }

                if(this.sentrySpan) {
                    this.sentrySpan.setStatus('unknown');
                    if(e && typeof e == 'object' && e.message && e.message.substring(0, 5) == 'AMQJS') {
                        const results = parseError(e.message);
                        if(results) {
                            switch(results.class) {
                                case ErrorClass.connectTimeout:
                                case ErrorClass.protocolTimeout:
                                    this.sentrySpan.setStatus('deadline_exceeded');
                                    break;
                                case ErrorClass.browserUnsupported:
                                    this.sentrySpan.setStatus('unimplemented');
                                    break;
                                case ErrorClass.badClient:
                                    this.sentrySpan.setStatus('invalid_argument');
                                    break;
                                case ErrorClass.protocolMalfunction:
                                    this.sentrySpan.setStatus('aborted');
                                    break;
                                case ErrorClass.serverRejected:
                                    this.sentrySpan.setStatus('permission_denied');
                                    break;
                            }
                        }
                    }
                    this.sentrySpan.setData('err', e);
                    this.sentrySpan.finish();
                    this.sentrySpan = undefined;
                }

                this.channel.once("connected", () => {
                    this.send();
                    if(this.sentryTrans) {
                        this.sentrySpan = this.sentryTrans.startChild({
                            op: 'mqtt',
                            description: 'Browser network request',
                        });
                        this.sentrySpan.setData('req', request.parms);
                    }
                });
                this.status = 'pending';
            }
        }, 0);
    }

    public cancel() {
        this.requestDone('canceled',undefined);
    }

    private onTimeout() {
        if(this.sentrySpan) {
            this.sentrySpan.setStatus('deadline_exceeded');
            this.sentrySpan.finish();
            this.sentrySpan = undefined;
        }
        this.emit('timeout');
        this.send();
    }

    public inProgress() : boolean {
        return !!this.removeCometMsgListener;
    }

    private onMessage(msg:NetworkResponseFrame,path:string) : void {
        if(!msg || !msg.original || !msg.result || msg.original.tag != this.tag) {
            return; // not directed to us
        }
        if(this.parms.timeoutRetry) {
            if(this.timeoutKey) {
                window.clearTimeout(this.timeoutKey);
            }
            this.timeoutKey = window.setTimeout(this.onTimeout.bind(this), this.parms.timeoutRetry);
        }
        switch(msg.result) {
            case 'done':
                this.emit('success', msg.inner);
                this.requestDone('success', msg.inner, msg.perf);
                break;
            case 'error':
                let err = new NetworkRequestError(msg.inner || msg, msg.original, this.requestPath);
                this.emit('error', err);
                this.requestDone('error', err);
                break;
            case 'progress':
                this.emit('progress', msg.inner);
                break;
        }
    }

    private onSessionChanged() : void {
        // our session has changed, need to resend all pending requests
        if(this.inProgress()) {
            this.send();
        }
    }

    private stopListeners() : void {
        if(this.timeoutKey) {
            window.clearTimeout(this.timeoutKey);
        }
        if(this.removeCometMsgListener) {
            this.removeCometMsgListener();
            this.removeCometMsgListener = null;
        }
        if(this.removeCometSessListener) {
            this.removeCometSessListener();
            this.removeCometSessListener = null;
        }
    }

    private requestDone(result:'canceled'|'success'|'error',msg:any,perf?:Record<string,any>) : void {
        this.stopListeners();
        if(this.sentrySpan && this.sentryTrans) {
            switch(result) {
                case 'canceled':
                    this.sentrySpan.setStatus('cancelled');
                    this.sentryTrans.setStatus('cancelled');
                    break;
                case 'success':
                    this.sentrySpan.setStatus('ok');
                    this.sentryTrans.setStatus('ok');
                    break;
                case 'error':
                    this.sentrySpan.setStatus('unknown');
                    this.sentryTrans.setStatus('unknown');
                    break;
            }
            this.sentrySpan.setData('msg', msg);
            this.sentrySpan.finish();
            if(perf) {
                if(perf.dispatcher) {
                    const disp : Record<string,number> = perf.dispatcher;
                    if(disp.start) {
                        const dispSpan = this.sentryTrans.startChild({
                            op: 'dispatcher',
                            description: 'Receives request from outside and dispatches',
                            startTimestamp: disp.start/1000,
                        });
                        let finishTimestamp : number = disp.start;
                        if(disp.dispatched) {
                            finishTimestamp = disp.start + disp.dispatched;
                            dispSpan.startChild({
                                op: 'dispatcher',
                                description: 'Dispatching request',
                                startTimestamp: disp.start/1000,
                            }).finish(finishTimestamp/1000);
                        }
                        if(disp.resolved) {
                            finishTimestamp = disp.start + disp.resolved;
                            dispSpan.startChild({
                                op: 'dispatcher',
                                description: 'Waiting for request resolution',
                                startTimestamp: (disp.start + (disp.dispatched||0))/1000,
                            }).finish(finishTimestamp/1000);
                        }
                        dispSpan.finish(finishTimestamp/1000);                        
                    }
                }
                if(perf.worker) {
                    const worker : Record<string,number> = perf.worker;
                    if(worker.start) {
                        const workerSpan = this.sentryTrans.startChild({
                            op: 'worker',
                            description: 'Receives and completes request',
                            startTimestamp: worker.start/1000,
                        });
                        let finishTimestamp : number = worker.start;
                        if(worker.dispatched) {
                            finishTimestamp = worker.start + worker.dispatched;
                            workerSpan.startChild({
                                op: 'worker',
                                description: 'Dispatching request',
                                startTimestamp: worker.start/1000,
                            }).finish(finishTimestamp/1000);
                        }
                        if(worker.resolved) {
                            finishTimestamp = worker.start + worker.resolved;
                            workerSpan.startChild({
                                op: 'worker',
                                description: 'Waiting for request resolution',
                                startTimestamp: (worker.start + (worker.dispatched||0))/1000,
                            }).finish(finishTimestamp/1000);
                        }
                        workerSpan.finish(finishTimestamp/1000);
                    }
                }
                if(perf.task && perf.task.start) {
                    const task = perf.task;
                    const taskStart : number = task.start;
                    const taskSpan = this.sentryTrans.startChild({
                        op: 'task',
                        description: 'Steps within the requested task',
                        startTimestamp: taskStart / 1000,
                    });
                    let finishTimestamp : number = taskStart;
                    const taskNames = Object.keys(task).sort((lhs,rhs) => task[lhs] < task[rhs] ? -1 : task[lhs] > task[rhs] ? +1 : 0);
                    const num = taskNames.length;
                    for(let idx=0; idx < num; idx++) {
                        const name = taskNames[idx];
                        if(name == 'start') continue;
                        if(task[name]) {
                            const startTimestamp : number = taskStart + (task[taskNames[idx-1]] || 0);
                            const endTimestamp : number = taskStart + task[name];
                            if(finishTimestamp < endTimestamp) finishTimestamp = endTimestamp;
                            taskSpan.startChild({
                                op: 'task',
                                description: name,
                                startTimestamp: startTimestamp/1000,
                            }).finish(endTimestamp/1000);
                        }
                    }
                    taskSpan.finish(finishTimestamp/1000);
                }
            }
            this.sentryTrans.finish();
            this.sentrySpan = undefined;
            this.sentryTrans = undefined;
        }
        if(this.status != 'idle') this.emit('done',result,msg);
        this.status = 'idle';
    }
}