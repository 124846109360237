/// <reference path="../../ext/event-emitter.ts"/>
/// <reference path="../types/mqtt.ts"/>
/// <reference path="./sdxf2.ts"/>
/// <reference path="./msgDiag.ts"/>

declare var Sentry:Readonly<any>;
declare var Paho:Readonly<{Client: PahoClient,Message: PahoMessage}>;

/*
declare var logNotification: (
	path:string,
	msg:Record<string,any>,
	messageID?:string
)=>void;
*/
interface CometAuth {
	url:string,
	uid:string,
	pwd:string
}

enum COMETStatus { disconnected, connecting, connected, disconnecting, failure, terminated};
enum ErrorClass { none, connectTimeout, protocolTimeout, error, protocolMalfunction, badClient,
	browserUnsupported, unexpectedCode, serverRejected };
const stripPath = /^tw\/client\/[^\/]+\/(.+)$/;
const errorExtract = /^AMQJSC?(\d+)[EI] /;
const serverErrorExtract = /^AMQJS0006E Bad Connack return code:(\d+) Connection Refused\: (.+).$/;
/*
1:"Connection Refused: unacceptable protocol version",
2:"Connection Refused: identifier rejected",
3:"Connection Refused: server unavailable",
4:"Connection Refused: bad user name or password",
5:"Connection Refused: not authorized"
};
*/
function parseError(err:string,isConnecting?:boolean) : undefined|{code:number,class:ErrorClass,rcCode?:number,rcMsg?:string} {
	const errMatch = err.match(errorExtract);
	if(!errMatch) return undefined;
	const ret : {code:number,class:ErrorClass,rcCode?:number,rcMsg?:string} = {
		code: parseInt(errMatch[1], 10),
		class: ErrorClass.unexpectedCode,
	}
	switch(ret.code) {
		case 0: // ok
			ret.class = ErrorClass.none;
			break;
		case 1: // Connect timed out.
			ret.class = ErrorClass.connectTimeout;
			break;
		case 2: // Subscribe timed out.
		case 3: // Unsubscribe timed out.
		case 4: // Ping timed out.
			ret.class = ErrorClass.protocolTimeout;
			break;
		case 5: // Internal error. Error Message: {0}, Stack trace: {1}
		case 7: // Socket error:{0}.
			ret.class = ErrorClass.error;
			break;
		case 8: // Socket closed.
			ret.class = isConnecting ? ErrorClass.serverRejected : ErrorClass.protocolMalfunction;
		case 6: { // Bad Connack return code:{0} {1}.
			const rcMatch = err.match(serverErrorExtract);
			if(rcMatch) {
				ret.rcCode = parseInt(rcMatch[1], 10);
				ret.rcMsg = rcMatch[2];
			}
			ret.class = ErrorClass.serverRejected;
			break;
		}
		case 9: // Malformed UTF data:{0} {1} {2}.
		case 15: // Invalid data in local storage key={0} value={1}.
		case 16: // Invalid MQTT message type {0}.
		case 17: // Malformed Unicode string:{0} {1}.
		case 18: // Message buffer is full, maximum buffer size: {0}.
			ret.class = ErrorClass.protocolMalfunction;
			break;
		case 10: // {0} is not supported by this browser.
			ret.class = ErrorClass.browserUnsupported;
			break;
		case 11: // Invalid state {0}.
		case 12: // Invalid type {0} for {1}.
		case 13: // Invalid argument {0} for {1}.
		case 14: // Unsupported operation.
			ret.class = ErrorClass.badClient;
			break;
		default:
			ret.class = ErrorClass.unexpectedCode;
			break;
	}
	return ret;
}

function objectExtend(target:{[index:string]:any}, src:{[index:string]:any}) : {[index:string]:any} {
	for(let key in src) {
		target[key] = src[key];
	}
	return target;
}

class COMETHandler extends EventEmitter<'statusChange'|'message'|'connected'|'disconnected'|'sessionChange'> {
	constructor(auth?:CometAuth) {
		super();
		if(auth) this.auth = auth;
		if(this.auth) this.createObjects();
	}

	public status : COMETStatus;
	private auth : CometAuth|null; // set by siteconfig
	public wsTimeout : number;
	public wsKeepalive : number;
	private sock : PahoClient|null;
	public qos : number; // set to 1 if we start dealing with persistent stuff?
	private boundSession : string|undefined|null;
	private abortActiveQuery: ((reason:string)=>void)|null;
	private connectOptions: any;

	protected static staticConstruct = (() => {
		const cp = COMETHandler.prototype;
		cp.status = COMETStatus.disconnected;
		cp.wsTimeout = 30;
		cp.wsKeepalive = 30;
		cp.qos = 1; // set to 1 if we start dealing with persistent stuff?
	})();

	public setStatus(newStat:COMETStatus, context:any) {
		this.status = newStat;
		this.emit('statusChange', newStat, context);
		switch(newStat) {
			case COMETStatus.connected:
				this.emit('connected', context);
				break;
			case COMETStatus.disconnected, COMETStatus.failure, COMETStatus.terminated:
				this.emit('disconnected', context);
				break;
		}
	}

	private createObjects() {
		if(!this.auth) return;
		const clientID = 'twclientid_' + parseInt((Math.random() * 65535).toFixed(0), 10).toString(16);
		try{
			this.sock = new Paho.Client(this.auth.url, clientID);
			this.sock.onConnectionLost = this.onConnectionLost.bind(this);
			this.sock.onMessageArrived = this.onMessageReceived.bind(this);
			this.sock.onConnected = this.onConnMade.bind(this);
		} catch(err){
			if('Sentry' in window) {
				Sentry.withScope((scope:any) => {
					scope.setTransaction('comet/createObjects');
					Sentry.captureMessage(err, Sentry.Severity.Error);
				});
			}
		}
	}

	public connect() {
		if(!this.sock || !this.auth) {
			if('Sentry' in window) {
				const err = new Error('MQTT library not properly initialized!');
				Sentry.withScope((scope:any) => {
					scope.setTransaction('comet/connect:onFailure');
					Sentry.captureMessage(err, Sentry.Severity.Error);
				});
			}
			return;
		}
		this.setStatus(COMETStatus.connecting, {loc:'comet/connect'});
		if('logNotification' in window) window.logNotification('', {isConfig:true, event:'CONNECT'});
		if('Sentry' in window) {
			Sentry.addBreadcrumb({
				category: 'comet.connect',
				message: 'Initiating connection',
				level: Sentry.Severity.Info,
			});
		}
		
		this.connectOptions = {
			timeout: this.wsTimeout || 30,
			userName: this.auth.uid,
			password: this.auth.pwd,
			keepAliveInterval: this.wsKeepalive || 60,
			reconnect: true,
			onFailure: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string}) => {
				this.setStatus(COMETStatus.terminated, objectExtend({loc:'comet/connect:onFailure'}, info));
				if('logNotification' in window) window.logNotification('', {isError:true, event:'CONNECT-CLOSED', code:PahoError[info.errorCode], msg:info.errorMessage});
				if('Sentry' in window) {
					const err = new Error('[' + PahoError[info.errorCode] + '] ' + info.errorMessage);
					Sentry.withScope((scope:any) => {
						scope.setExtra('info', info);
						scope.setExtra('options', this.connectOptions);
						scope.setTransaction('comet/connect:onFailure');
						Sentry.captureMessage(err, Sentry.Severity.Error);
					});
				}
				//alert('COMET: conn failure ' + PahoError[info.errorCode] + ': ' + info.errorMessage);
			}
		};
		
		try {
			this.sock.connect(this.connectOptions);
		} catch(e) {
			if('logNotification' in window) window.logNotification('', {isError:true, event:'CONNECT-ERROR', msg:e});
			this.setStatus(COMETStatus.terminated, {loc:'comet/connect',error:e});
			if('Sentry' in window) {
				Sentry.withScope((scope:any) => {
					scope.setTransaction('comet/connect');
					Sentry.captureException(e);
				});
			}
			//alert('COMET: conn failure ' + e);
		}
	}

	public disconnect() {
		if(this.sock) {
			if('logNotification' in window) window.logNotification('', {isConfig:true, event:'DISCONNECT'});
			this.sock.disconnect();
			this.setStatus(COMETStatus.disconnected, {loc:'comet/disconnect'});
			if('Sentry' in window) {
				Sentry.addBreadcrumb({
					category: 'comet.disconnect',
					message: 'Connection disconnect requested',
					level: Sentry.Severity.Info,
				});
			}
			this.boundSession = null;
		}
	}

	private onConnMade(reconn:boolean, uri:string) {
		if('logNotification' in window) window.logNotification('', {isConfig:true, event:'CONNECTED', reconn:reconn, uri:uri});
		this.sessionChanged(this.boundSession, true).then(() => {
			this.setStatus(COMETStatus.connected, {loc:'comet/onConnMade',reconnect:reconn,uri:uri});
		});
	}

	private unsubscribeOldPath(oldPath:string) : Promise<void> {
		if(!this.sock) return Promise.reject(new Error("Requires an active socket"));
		return new Promise<{invocationContext:Object}>((resolve, reject) => {
				this.abortActiveQuery = (reason:string) => {
					reject({invocationContext:{},errorCode:PahoError.OK,errorMessage:reason});
				}
				this.sock!.unsubscribe(oldPath, {
					invocationContext: {path:oldPath},
					onSuccess: (info:{invocationContext:Object}):void => { resolve(info!); },
					onFailure: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string}):void => { reject(info!); }
				});
			})
			.then((info:{invocationContext:Object}):void => {
				// ... and we're done!
				this.abortActiveQuery = null;
				if('logNotification' in window) window.logNotification(oldPath, {isConfig:true, event:'UNSUBSCRIBED'});
				if('Sentry' in window) {
					Sentry.addBreadcrumb({
						category: 'comet.sessionChange.unsubscribe',
						message: 'Subscription successfully removed',
						level: Sentry.Severity.Info,
					});
				}
			}, (info: {invocationContext:Object,errorCode:PahoError,errorMessage:string}):void => {
				this.abortActiveQuery = null;
				if('logNotification' in window) window.logNotification(oldPath, {isError:true, event:'UNSUBSCRIBE-FAILURE', code:PahoError[info.errorCode], msg:info.errorMessage});
				if('Sentry' in window) {
					const err = new Error('[' + PahoError[info.errorCode] + '] ' + info.errorMessage);
					Sentry.withScope((scope:any) => {
						scope.setExtra('info', info);
						scope.setExtra('context', info.invocationContext);
						scope.setTransaction('comet/sessionChange/unsubscribe:onFailure');
						Sentry.captureMessage(err, Sentry.Severity.Error);
					});
				}
				alert('COMET: unsubscribe failure ' + PahoError[info.errorCode] + ': ' + info.errorMessage);
			});
	}

	public sessionChanged(newSession:string|null|undefined,force?:boolean) : Promise<void> {
		if(!force && (this.status != COMETStatus.connected || newSession == this.boundSession)) return Promise.resolve();
		const oldSession = this.boundSession;
		const newPath = newSession ? 'tw/client/'+newSession+'/#' : 'dev/null';
		const oldPath = oldSession ? 'tw/client/'+oldSession+'/#' : 'dev/null';

		const subscribeFailure = (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string}) : Error => {
			this.abortActiveQuery = null;
			if('logNotification' in window) window.logNotification(newPath, {isError:true, event:'SUBSCRIBE-FAILURE', code:PahoError[info.errorCode], msg:info.errorMessage});
			const err = new Error('[' + PahoError[info.errorCode] + '] ' + info.errorMessage);
			if('Sentry' in window) {
				Sentry.withScope((scope:any) => {
					scope.setExtra('info', info);
					scope.setExtra('context', info.invocationContext);
					scope.setTransaction('comet/sessionChange/subscribe:onFailure');
					Sentry.captureMessage(err, Sentry.Severity.Error);
				});
			}
			alert('COMET: subscribe failure ' + PahoError[info.errorCode] + ': ' + info.errorMessage);
			return err;
		}

		if(!this.sock) return Promise.reject(new Error("Requires an active socket"));
		
		return new Promise<{invocationContext:Object,grantedQos:number}>((resolve, reject) => {
				this.abortActiveQuery = (reason:string) => {
					reject(subscribeFailure({invocationContext:{},errorCode:PahoError.OK,errorMessage:reason}));
				}
				this.sock!.subscribe(newPath, {
					invocationContext: {path:newPath},
					qos: this.qos,
					onSuccess: (info:{invocationContext:Object,grantedQos:number}):void => { resolve(info!); },
					onFailure: (info:{invocationContext:Object,errorCode:PahoError,errorMessage:string}):void => { reject(subscribeFailure(info!)); }
				});
			})
			.then((info:{invocationContext:Object,grantedQos:number}) => {
				this.abortActiveQuery = null;
				if('logNotification' in window) window.logNotification(newPath, {isConfig:true, event:'SUBSCRIBED'});
				if('Sentry' in window) {
					Sentry.addBreadcrumb({
						category: 'comet.sessionChange.subscribe',
						message: 'Subscription successfully established',
						level: Sentry.Severity.Info,
					});
				}
				if(newSession != this.boundSession) {
					this.boundSession = newSession;
					this.unsubscribeOldPath(oldPath);
					this.emit('sessionChange');
				}
			});
	}

	private onConnectionLost(info:{errorCode:PahoError,errorMessage:string,reconnect:boolean,uri:string,event:any}) {
		let fatalReason : string|null = null;
		switch(info.errorCode) {
			case PahoError.SOCKET_CLOSE:
				if(info.event?.wasClean && info.event.reason) {
					// closed due to RabbitMQ error, do NOT auto-reconnect
					fatalReason = info.event.reason;
				}
				break;
			case PahoError.INTERNAL_ERROR:
				fatalReason = info.errorMessage;
				break;
		}
		if(fatalReason) {
			if(this.abortActiveQuery) this.abortActiveQuery(fatalReason);
			if(this.connectOptions) this.connectOptions.reconnect = false;
			if(info.reconnect) info.reconnect = false;
			if('logNotification' in window) window.logNotification('', {isConfig:true, event:'CONNECT-TERMINATED', reason:fatalReason});
			this.setStatus(COMETStatus.terminated, objectExtend({loc:'comet/onConnectionLost',reason:fatalReason}, info));
		} else {
			if('logNotification' in window) window.logNotification('', {isConfig:true, event:'CONNECT-LOST', code:PahoError[info.errorCode], msg:info.errorMessage});
			this.setStatus(COMETStatus.failure, objectExtend({loc:'comet/onConnectionLost'}, info));
		}
		if('Sentry' in window) {
			Sentry.addBreadcrumb({
				category: 'comet.onConnectionLost',
				message: 'Connection lost',
				level: Sentry.Severity.Warning,
			});
		}
	}

	private onMessageReceived(frame:PahoMessage) {

		const processMessage = () => {
			// determine the datatype of this message
			const payloadRaw : Uint8Array|string = (frame as any).payloadRaw;
			if(!payloadRaw.length) return;

			let firstChar : number = -1;
			if (typeof payloadRaw === "string") {
				firstChar = payloadRaw.charCodeAt(0);
			} else {
				firstChar = payloadRaw[0];
			}

			let msg : Object|string|null = null;
			switch(firstChar) {
				case 0x3c: // '<', xml
					msg = frame.payloadString; // don't attempt to parse here
					break;
				case 0x7b: // '{', json
				case 0x5b: // '[', json
					const payloadString = frame.payloadString;
					if(payloadString) {
						try
						{
							msg = JSON.parse(payloadString);
						} catch(e) {
							if('Sentry' in window) {
								Sentry.withScope((scope:any) => {
									scope.setTransaction('comet/onMessageReceived');
									Sentry.captureException(e);
								});
							}
							return;
						}
					}
					break;
				case 0: // sdxf2
					msg = SDXF2.sdxf2_decode(payloadRaw);
					if(msg && (msg as any).length == 1) msg = (msg as any)[0];
					break;
				default: // guessed as sdxf2
					debugger; // not recognized!
					msg = SDXF2.sdxf2_decode(payloadRaw);
					if(msg && (msg as any).length == 1) msg = (msg as any)[0];
					break;
			}
			const reMatch = frame.destinationName.match(stripPath);
			const path = reMatch ? reMatch[1] : frame.destinationName;
			if('logNotification' in window) window.logNotification(path, msg as any);
			this.emit('message', msg, path);
		}
		
		window.setTimeout(processMessage, 0);
	}

	public send(topic:string,payload:any) {
		if(!this.sock) return;
		if('logNotification' in window) window.logNotification('sending', payload);
		if(typeof payload == 'object') {
			if(payload instanceof Uint8Array) {
				payload = payload.buffer;
			}
			if(!(payload instanceof ArrayBuffer)) {
                payload = SDXF2.sdxf2_encode(payload).toArrayBuffer();
			}
		}
		this.sock.send(topic, payload);
	}

	public rabbitQueueName():string|null {
		if(this.sock) {
			return 'mqtt-subscription-'+this.sock.clientId+'qos'+this.qos;
		} else {
			return null;
		}
	}
}
